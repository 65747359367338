<template>
  <v-skeleton-loader
    type="card, article"
    :loading="
      $store.state.recommendations.detailStatus === 'fetching' &&
      $route.params.id !== item.id
    "
  >
    <v-container
      v-if="item"
      class="pt-0 px-0 margin-bottom-custom"
      style="overflow-x: hidden"
    >
      <musement
        v-if="item.source === 'musement'"
        :item="item"
        @read-more="readMore"
        @toggle="
          (id) => {
            toggleFavorite(id);
          }
        "
        @show-ratings="showRating = true"
        :readMoreActivated="readMoreActivated"
        :ratings="ratings"
        :openingTimes="openingTimes"
        :validSubcategories="validSubcategories"
        :displayedAddress="displayedAddress"
        :distance="distance"
        :filteredRecommendations="filteredRecommendations"
      ></musement>
      <ticketmaster
        v-if="item.source === 'ticketmaster'"
        :item="item"
        @toggle="
          (id) => {
            toggleFavorite(id);
          }
        "
        @show-ratings="showRating = true"
        @read-more="readMore"
        :readMoreActivated="readMoreActivated"
        :ratings="ratings"
        :openingTimes="openingTimes"
        :validSubcategories="validSubcategories"
        :displayedAddress="displayedAddress"
        :distance="distance"
        :filteredRecommendations="filteredRecommendations"
      ></ticketmaster>
      <get-your-guide
        v-if="item.source === 'getyourguide'"
        :item="item"
        @toggle="
          (id) => {
            toggleFavorite(id);
          }
        "
        @show-ratings="showRating = true"
        @read-more="readMore"
        :readMoreActivated="readMoreActivated"
        :ratings="ratings"
        :openingTimes="openingTimes"
        :validSubcategories="validSubcategories"
        :displayedAddress="displayedAddress"
        :distance="distance"
        :filteredRecommendations="filteredRecommendations"
      ></get-your-guide>
      <v1
        v-if="
          item.source === 'V1' || !item.source || item.source === 'reserve-out'
        "
        :item="item"
        @toggle="
          (id) => {
            toggleFavorite(id);
          }
        "
        @show-ratings="showRating = true"
        @read-more="readMore"
        :readMoreActivated="readMoreActivated"
        :ratings="ratings"
        :openingTimes="openingTimes"
        :validSubcategories="validSubcategories"
        :displayedAddress="displayedAddress"
        :distance="distance"
        :filteredRecommendations="filteredRecommendations"
      ></v1>
      <v-bottom-sheet scrollable v-model="showRating">
        <v-sheet
          class="pt-4"
          height="85vh"
          style="border-radius: 20px 20px 0 0"
        >
          <div class="reviews">
            <v-card
              flat
              v-for="(item, index) in ratings"
              :key="`rating-${index}`"
            >
              <v-row dense justify="center" align="center" class="pl-4">
                <v-col cols="2">
                  <v-img
                    height="44"
                    width="44"
                    :src="
                      item.img && item.img.length > 0
                        ? item.img
                        : require('@/assets/avatar.png')
                    "
                  ></v-img>
                </v-col>
                <v-col cols="5" class="text-left">
                  <v-card-text class="py-0">{{ item.alias }}</v-card-text>
                  <v-card-text class="py-0 caption">{{
                    $t("review.postedAt", { date: item.postDate })
                  }}</v-card-text>
                </v-col>
                <v-col cols="5">
                  <v-rating
                    readonly
                    class="d-inline-block mr-2"
                    background-color="#feeecb"
                    empty-icon="mdi-star"
                    full-icon="mdi-star"
                    length="5"
                    size="20"
                    :value="parseInt(item.rating)"
                    color="#fdbd2f"
                  ></v-rating>
                </v-col>
              </v-row>
              <v-card-text class="pt-0 text-left">{{
                item.comment
              }}</v-card-text>
            </v-card>
          </div>
          <!--
          <v-row justify="center" align="center" class="pt-2">
            <v-btn
              height="36"
              @click="
                $router.safePush({
                  name: 'rating',
                  params: { detailId: item.id },
                })
              "
              depressed
              color="secondary"
              class="secondary-gradient rounded-xl Heading-White-H5-16-Center"
              >{{ $t("detail.rating") }}</v-btn
            ></v-row
          >
          -->
        </v-sheet>
      </v-bottom-sheet>
    </v-container>
  </v-skeleton-loader>
</template>
<script>
import moment from "moment";
import find from "lodash/find";
import filter from "lodash/filter";
import indexOf from "lodash/indexOf";
import { mapMutations, mapActions, mapGetters } from "vuex";
import Musement from "@/components/recommendations/Detail/Musement";
import Ticketmaster from "@/components/recommendations/Detail/Ticketmaster";
import GetYourGuide from "@/components/recommendations/Detail/GetYourGuide";
import V1 from "@/components/recommendations/Detail/V1";
import api from "@/api";

export default {
  data: () => ({
    showRating: false,
    priceIndicator: "$$$$",
    readMoreActivated: false,
  }),
  computed: {
    ...mapGetters("recommendations", ["filteredRecommendations"]),
    item() {
      let item = filter(
        this.$store.getters["recommendations/allItems"],
        (item) => {
          return item.id == this.$route.params.id;
        }
      )[0];
      return item || this.$store.state.recommendations.details;
    },
    validSubcategories() {
      return this.item.subCategory
        ? this.item.subCategory
            .filter((el) => el != null)
            .map((a) => (a.title ? a.title : a))
            .join(", ")
        : "";
    },
    displayedAddress() {
      if (
        this.item.addressLine1 ||
        this.item.addressLine2 ||
        this.item.streetNumber ||
        this.item.zipCode
      ) {
        return (
          (this.item.addressLine1 ? this.item.addressLine1 : "") +
          (this.item.addressLine2 ? ", " + this.item.addressLine2 : "") +
          (this.item.streetNumber ? ", " + this.item.streetNumber : "") +
          (this.item.zipCode ? ", " + this.item.zipCode : "")
        );
      } else if (this.item.location && this.item.location.address)
        return this.item.location.address;
      else {
        return "";
      }
    },
    ratings() {
      return this.$store.state.recommendations.details.ratings;
    },
    openingTimes() {
      return api.getOpeningHours(this.item);
    },
    distance() {
      try {
        return api.getDistanceToLocation(this.item);
      } catch (error) {
        console.error("Failed to get distance");
        return "0";
      }
    },
  },
  created() {
    /* Fix -> sometimes the view would not be scrolled to the very top */
    this.$vuetify.goTo(0);
  },
  components: {
    Musement,
    Ticketmaster,
    GetYourGuide,
    V1,
  },
  methods: {
    ...mapMutations({
      setMarker: "map/SET_MARKER",
      setCenter: "map/SET_CENTER",
    }),
    ...mapActions("favorites", ["toggleFavorite"]),
    openPage(link) {
      if (
        this.$store.state.isMiKY &&
        (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
      ) {
        window.location.assign(link);
      } else {
        window.open(link, "_system");
      }
    },
    readMore() {
      this.readMoreActivated = !this.readMoreActivated;
    },
    toggle(id) {
      if (
        !find(this.$store.state.profile.data.favorites, (fav) => {
          return fav === id;
        })
      ) {
        if (!this.$store.state.profile.data.favorites) {
          this.$store.state.profile.data.favorites = [];
        }
        this.$store.state.profile.data.favorites.push(id);
      } else {
        this.$store.state.profile.data.favorites.splice(
          indexOf(this.$store.state.profile.data.favorites, id),
          1
        );
      }

      this.$store.dispatch(
        "profile/updateProfile",
        {
          profile: this.$store.state.profile.data,
        },
        { root: true }
      );
    },
    find,
    moment,
  },
};
</script>

<style lang="scss">
.v-application p {
  margin-bottom: 0;
}

p {
  span {
    color: inherit !important;
    background-color: transparent !important;
  }
}
/* Places content correctly above the bottom navigation bar due to screen inset on iPhone X and similar phones */
.margin-bottom-custom {
  margin-bottom: env(safe-area-inset-bottom, 64px) !important;
}
.v-btn:before {
  background-color: transparent;
}
.v-rating .v-icon {
  padding: 0 !important;
}
.v-carousel__controls .v-item-group {
  margin-top: -80px;
}
.v-carousel__controls__item {
  width: 20px !important;
  height: 20px !important;
  margin: 0;
  font-size: 11px;
}
.v-carousel__controls__item span i {
  font-size: 11px !important;
  color: #fff !important;
  opacity: 1 !important;
}
.v-carousel__controls__item.v-item--active span i {
  width: 10px;
  height: 10px;
  color: radial-gradient(
    circle at 0 8%,
    var(--color-primary-1-blue),
    #56c0df 77%,
    var(--color-primary-2-blue-light) 95%
  ) !important;
  font-size: 11px !important;
  opacity: 1 !important;
}
.reviews {
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (max-height: 600px) {
  .reviews {
    height: 72vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
