<template>
  <div>
    <v-carousel
      v-if="item.imageGallery && item.imageGallery.length > 0"
      height="auto"
      :show-arrows="false"
      continuous
      hide-delimiter-background
      :hide-delimiters="item.imageGallery && item.imageGallery.length < 2"
    >
      <v-carousel-item
        v-for="(item, i) in item.imageGallery"
        :key="i"
        :min-height="carouselItemHeight"
        max-height="50vh"
        :aspect-ratio="$vuetify.breakpoint.smAndUp ? Number(16 / 9) : undefined"
      >
        <VueLoadImage>
          <img
            slot="image"
            height="100%"
            width="100%"
            style="object-fit: cover; z-index: -1; position: absolute"
            :src="item"
          />
          <img
            slot="preloader"
            :src="require('../../../../public/loader.gif')"
            height="100%"
            width="100%"
            style="object-fit: contain; z-index: -1; position: absolute"
          />
          <img slot="error" src="" />
        </VueLoadImage>
        <v-toolbar
          flat
          dense
          class="transparent"
          style="padding-top: env(safe-area-inset-top)"
        >
          <v-btn
            style="z-index: 1; width: 37px; height: 37px; margin-left: -4px"
            class="image-action"
            top
            v-if="!showAsDialog"
            absolute
            icon
            small
            @click.stop="$router.back()"
            ><img src="@/assets/img/arrow-left.svg" style="margin-left: -4px"
          /></v-btn>
          <v-spacer></v-spacer>
          <v-btn
            style="z-index: 1; width: 37px; height: 37px"
            class="image-action mt-5 mr-2"
            small
            icon
            @click.stop="share()"
            ><img src="@/assets/img/share.svg" style="margin-left: -4px"
          /></v-btn>
          <v-btn
            style="z-index: 1"
            class="image-action"
            v-if="showAsDialog"
            small
            icon
            @click.stop="$emit('close-dialog')"
            ><v-icon large color="white">mdi-close</v-icon>
          </v-btn>
          <v-btn
            icon
            width="37"
            height="37"
            style="z-index: 11"
            class="mt-5"
            v-if="!showAsDialog"
            @click="showLocationDialog = true"
          >
            <v-img
              width="37"
              height="37"
              :src="require('@/assets/img/curved_arrow.svg')"
            ></v-img>
          </v-btn>
        </v-toolbar>
      </v-carousel-item>
    </v-carousel>
    <v-img
      v-if="highResMusementImage"
      :src="highResMusementImage"
      :lazy-src="item.affiliateImage"
      max-height="50vh"
      :aspect-ratio="$vuetify.breakpoint.smAndUp ? Number(16 / 9) : undefined"
      min-height="35vh"
      height="auto"
      ><v-toolbar
        flat
        dense
        class="transparent"
        style="padding-top: env(safe-area-inset-top)"
      >
        <v-btn
          style="z-index: 1; width: 37px; height: 37px; margin-left: -4px"
          class="image-action"
          top
          v-if="!showAsDialog"
          absolute
          icon
          small
          @click.stop="$router.back()"
          ><img src="@/assets/img/arrow-left.svg" style="margin-left: -4px"
        /></v-btn>
        <v-spacer></v-spacer>
        <v-btn
          style="z-index: 1; width: 37px; height: 37px"
          class="image-action mt-5 mr-2"
          small
          icon
          @click.stop="share()"
          ><img src="@/assets/img/share.svg" style="margin-left: -4px"
        /></v-btn>
        <v-btn
          style="z-index: 1"
          class="image-action"
          v-if="showAsDialog"
          small
          icon
          @click.stop="$emit('close-dialog')"
          ><v-icon large color="white">mdi-close</v-icon>
        </v-btn>
        <v-btn
          icon
          width="37"
          height="37"
          style="z-index: 11"
          class="mt-5"
          v-if="!showAsDialog"
          @click="showLocationDialog = true"
        >
          <v-img
            width="37"
            height="37"
            :src="require('@/assets/img/curved_arrow.svg')"
          ></v-img>
        </v-btn> </v-toolbar
    ></v-img>
    <v-card style="border-radius: 20px 20px 0 0 !important" flat class="mt-n5">
      <v-container>
        <v-card-actions class="pt-4">
          <span
            class="caption white--text"
            style="position: absolute; top: -20px"
          >
            {{
              $t("review.powered", {
                value: item.source,
              })
            }}</span
          >
          <v-btn
            v-if="
              $store.state.profile.data &&
              $store.state.session.user &&
              !$store.state.isMiKY
            "
            absolute
            right
            top
            elevation="0"
            fab
            large
            @click.stop="$emit('toggle', item.id)"
            :style="
              !$store.state.settings.dark
                ? 'background-color:#fff;'
                : 'background-color: #1e1e1e;'
            "
            ><v-icon
              x-large
              :color="
                find($store.state.profile.data.favorites, (fav) => {
                  return fav === item.id;
                })
                  ? 'red'
                  : '#475960'
              "
              >{{
                find($store.state.profile.data.favorites, (fav) => {
                  return fav === item.id;
                })
                  ? "mdi-heart"
                  : "mdi-heart-outline"
              }}</v-icon
            ></v-btn
          >
          <v-btn
            icon
            v-if="item.weatherConditions == 'FairWeather'"
            class="ml-0"
          >
            <img width="25px" height="25px" src="@/assets/img/sun.svg"
          /></v-btn>
          <v-btn
            icon
            v-else-if="item.weatherConditions == 'BadWeather'"
            class="ml-0"
            ><img width="25px" height="25px" src="@/assets/img/cloud.svg"
          /></v-btn>
          <div
            v-if="openingTimes"
            v-html="openingTimes"
            style="width: 100%; position: absolute; left: 0"
            class="v-html Text-Dark-Text-2---16-Center"
          ></div>
          <v-spacer></v-spacer>
          <div
            class="Text-Dark-Text-2---16-Left-Align"
            v-if="item.priceSegment && !item.price"
          >
            {{ item.priceSegment }}
          </div>
          <div
            class="Text-Dark-Text-2---16-Left-Align"
            style="color: #cacaca !important"
            v-if="item.priceSegment && !item.price"
          >
            {{ "$$$$".slice(0, 4 - item.priceSegment.length) }}
          </div>
          <div v-if="item.price" class="Heading-Dark-H4-18-Left-Align">
            ${{ item.price }}
          </div>
        </v-card-actions>
        <v-card-title
          v-if="item.header"
          class="py-2 justify-center HeadingDarkH3-22Center"
          style="word-break: break-word"
          >{{ item.header }}</v-card-title
        >
        <v-card-text class="pt-2 px-0">
          <template
            v-if="
              $route.name === 'detail' &&
              item.location &&
              displayedAddress &&
              displayedAddress.length > 0
            "
          >
            <v-btn
              class="d-inline-block"
              v-if="$route.name === 'detail'"
              icon
              @click="showLocationDialog = true"
            >
              <img src="@/assets/img/location.svg" />
            </v-btn>
            <open-in-maps
              :lat="lat"
              :lng="lng"
              :value="location"
              @cancel="location = false"
            ></open-in-maps>
            <div
              style="color: #606060; vertical-align: middle; max-width: 65%"
              class="Text-Dark-Text-3---14-Left-Align d-inline-block"
            >
              {{ displayedAddress }}
            </div>
          </template>
          <div
            v-if="distance"
            class="Text-Dark-Text-3---14-Right-Align"
            :class="displayedAddress ? 'd-inline-block' : ''"
            style="position: absolute; right: 20px"
          >
            <span>{{ distance }} km</span>
            <v-img
              class="d-inline-block"
              :class="$store.state.settings.dark ? 'filter' : ''"
              height="25"
              width="24"
              :src="require('@/assets/distance.svg')"
            />
          </div>
        </v-card-text>
        <v-card-actions v-if="item.category">
          <template v-if="item.category.pictogram">
            <div class="mr-2 gradient pa-2 rounded-circle">
              <v-img
                class="filter"
                width="20px"
                height="auto"
                :src="item.category.pictogram"
              />
            </div>
          </template>
          <span
            :class="!item.category.pictogram ? 'ml-2' : ''"
            class="Text-Dark-Text-1---18-Left-Align mt-1"
          >
            {{ $t(`categories.${item.category.title}`) }}
          </span>
          <v-spacer></v-spacer>
          <template v-if="$store.state.recommendations.details.userRating">
            <a @click="$emit('show-ratings')" v-if="ratings">
              <v-rating
                readonly
                class="d-inline-block mr-2"
                background-color="#feeecb"
                empty-icon="mdi-star"
                full-icon="mdi-star"
                length="5"
                size="20"
                :value="
                  parseInt($store.state.recommendations.details.userRating)
                "
                color="#fdbd2f"
              ></v-rating>
              <span
                class="Text-Dark-Text-3---14-Right-Align mt-1"
                v-if="ratings.length"
                >{{ ratings.length }}</span
              >
            </a>
            <v-rating
              v-else
              readonly
              class="d-inline-block mr-2"
              background-color="#feeecb"
              empty-icon="mdi-star"
              full-icon="mdi-star"
              length="5"
              size="20"
              :value="parseInt($store.state.recommendations.details.userRating)"
              color="#fdbd2f"
            ></v-rating>
          </template>
        </v-card-actions>
        <v-card-text
          v-if="!readMoreActivated && item.detailedDescription"
          class="Text-Dark-Text-2---16-Left-Align"
        >
          {{
            itemDescriptionStripped
              .slice(0, 225)
              .concat(itemDescriptionStripped > 225 ? " ..." : "")
          }}

          <a
            v-if="item.detailedDescription.length > 225 && !readMoreActivated"
            @click="$emit('read-more')"
            color="primary"
            >{{ $t("dashboard.showMore") }}
            <img
              src="@/assets/img/icons-outlines-16-px-read-more.svg"
              class="color-filter Icons-Outlines-16px-Read-more"
            />
          </a>
        </v-card-text>
        <v-card-text
          class="Text-Dark-Text-2---16-Left-Align"
          v-if="readMoreActivated && item.detailedDescription"
        >
          {{ itemDescriptionStripped }}
          <a color="primary" @click="$emit('read-more')">
            {{ $t("dashboard.showLess") }}
            <img
              src="@/assets/img/icons-outlines-16-px-read-less.svg"
              class="color-filter Icons-Outlines-16px-Read-less"
            />
          </a>
        </v-card-text>
        <v-card-text
          class="v-html Text-Dark-Text-2---16-Center pt-0"
          v-if="item.date && item.time"
          v-html="item.date + ', ' + item.time"
        ></v-card-text>
        <div v-if="validSubcategories" class="px-3">
          <v-divider color="#d0c9d6" />
          <v-card-text class="py-2 px-0 Text-Dark-Text-2---16-Left-Align">
            {{ validSubcategories }}
          </v-card-text>
          <v-divider color="#d0c9d6" />
        </div>
        <v-card-actions
          style="justify-content: center; margin-bottom: 0 !important"
        >
          <bottom-buttons :item="item"></bottom-buttons>
        </v-card-actions>

        <template v-if="item.promoter">
          <v-card-text class="text-center">{{ item.promoter }}</v-card-text>
          <v-divider class="mx-4"></v-divider>
        </template>
        <template
          v-if="
            item.openingHours &&
            item.openingHours != '<p></p>\n' &&
            item.openingHours.length > 0
          "
        >
          <v-card-actions class="pb-0">
            <v-btn icon>
              <v-icon class="gradient-icon">mdi-clock</v-icon>
            </v-btn>
            <span class="Heading-Dark-H5-16-Left-Align">{{
              $t("detail.openingHours")
            }}</span>
          </v-card-actions>
          <v-card-text
            class="v-html py-0 Text-Dark-Text-2---16-Left-Align"
            v-html="item.openingHours"
          >
          </v-card-text>
        </template>
        <v-card-text v-if="$store.state.session.user" class="pt-0 pl-2">
          <v-btn
            icon
            @click="
              $router.safePush({
                name: 'rating',
                params: { detailId: item.id },
              })
            "
            ><img src="@/assets/img/rating.svg"
          /></v-btn>
          <router-link
            class="mt-n2 ml-4 Text-Color-Text-2---16-Left-Align d-block"
            :to="{ name: 'rating', params: { detailId: item.id } }"
            >{{ $t("detail.rating") }}</router-link
          >
        </v-card-text>
        <v-card-actions
          v-if="
            item.callToAction &&
            item.category.callToActionLabel &&
            !showAsDialog
          "
          class="px-3"
        >
          <v-btn
            height="48"
            block
            depressed
            @click="openPage(item.callToAction)"
            color="primary"
            class="gradient Heading-White-H4-18-Center"
            >{{ item.category.callToActionLabel }}</v-btn
          ></v-card-actions
        >
        <v-card-actions
          v-if="
            item.callToAction2 &&
            item.category.callToActionLabel2 &&
            !showAsDialog
          "
          class="px-3"
        >
          <v-btn
            height="48"
            block
            depressed
            @click="openPage(item.callToAction2)"
            color="primary"
            class="gradient Heading-White-H4-18-Center"
            >{{ item.category.callToActionLabel2 }}</v-btn
          ></v-card-actions
        >
        <template v-if="item.venues && item.venues.length > 0">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.venues[0].name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.venues[0].address.line1
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                item.venues[0].city.name
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="item.venues.length > 1" class="mx-4"></v-divider>
        </template>
        <additional-event-list
          v-if="item.allEvents && item.allEvents.length > 1"
          :events="item.allEvents"
        ></additional-event-list>
        <v-list
          flat
          class="transparent pt-2"
          v-if="item.menu || item.reservation"
        >
          <v-list-item v-if="item.menu" @click="openPage(item.menu)">
            <v-list-item-avatar
              ><v-icon>mdi-silverware</v-icon></v-list-item-avatar
            >
            <v-list-item-subtitle>{{ $t("detail.menu") }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item
            v-if="item.reservation"
            @click="openPage(item.reservation)"
          >
            <v-list-item-avatar>
              <v-icon>mdi-calendar</v-icon></v-list-item-avatar
            >
            <v-list-item-subtitle>{{
              $t("detail.reserveTable")
            }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-container>
      <v-container class="pr-0 pt-0">
        <template
          v-if="filteredRecommendations && filteredRecommendations.length > 0"
        >
          <v-row align="center" justify="center">
            <h2 class="HeadingDarkH3-22Left-Align ml-7">
              {{ $t("dashboard.similar") }}
            </h2>
            <v-spacer></v-spacer
            ><v-btn
              class="Text-Color-Text-3---14-Left-Align mr-4"
              text
              color="primary"
              @click="
                $router.safePush({
                  name: 'allRecommendations',
                  query: {
                    ...$router.currentRoute.query,
                    type: 'filteredRecommendations',
                  },
                })
              "
              >{{ $t("dashboard.seeAll") }}</v-btn
            >
          </v-row>
          <recommendation-slider
            class="ml-4"
            :filteredRecommendations="filteredRecommendations"
          ></recommendation-slider>
        </template>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import BottomButtons from "@/components/BottomButtons";
import AdditionalEventList from "@/components/AdditionalEventList";
import find from "lodash/find";
import indexOf from "lodash/indexOf";
import { mapMutations } from "vuex";
import RecommendationSlider from "@/components/dashboard/RecommendationSlider";
import OpenInMaps from "@/components/app/maps/OpenInMaps";
import VueLoadImage from "vue-load-image";

export default {
  data: () => ({
    showLocationDialog: false,
  }),
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filteredRecommendations: {
      type: Array,
      validator: (prop) => prop.every((item) => typeof item === "object"),
    },
    ratings: {
      type: Array,
      validator: (prop) => prop.every((item) => typeof item === "object"),
    },
    readMoreActivated: {
      type: Boolean,
      default: false,
    },
    showAsDialog: {
      type: Boolean,
      default: false,
    },
    openingTimes: {
      type: String,
      default: "",
    },
    validSubcategories: {
      type: String,
      default: "",
    },
    displayedAddress: {
      type: String,
      default: "",
    },
    distance: {
      type: String,
      default: "",
    },
  },
  components: {
    BottomButtons,
    AdditionalEventList,
    RecommendationSlider,
    OpenInMaps,
    VueLoadImage,
  },
  /* Filter to remove html tags in rendered text */
  filters: {
    strippedContent(string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  computed: {
    highResMusementImage() {
      let imageUrl = this.item.affiliateImage;

      if (!imageUrl) {
        return require("../../../assets/image_placeholder.png");
      }

      let imageWidth = imageUrl.substr(imageUrl.length - 5);
      let highResUrl = imageUrl;
      if (imageWidth === "w=540") {
        highResUrl = imageUrl.slice(0, imageUrl.length - 5) + "w=1404";
      }
      return highResUrl;
    },
    itemDescriptionStripped() {
      return this.item.detailedDescription.replace(/<\/?[^>]+>/gi, " ");
    },
    carouselItemHeight() {
      // Calculate image height to be in ratio 16/9
      const ratio = 16 / 9;
      const window_width = window.innerWidth;
      const window_height = window.innerHeight;
      const height = window_width / ratio;
      return height > window_height / 50 ? "50vh" : height;
    },
  },
  methods: {
    share() {
      var options = {
        // message: this.item.header, // not supported on some apps (Facebook, Instagram)
        // subject: this.item.header, // fi. for email
        // files: [' ', ' '], // an array of filenames either locally or remotely
        url: `https://po-co.app/#/detail/${this.item.id}`,
        // chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
        // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        // iPadCoordinates: '0,0,200,200' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
      };
      var onSuccess = function (result) {
        console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
        console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      };

      var onError = function (msg) {
        console.log("Sharing failed with message: " + msg);
      };
      try {
        window.plugins.socialsharing.shareWithOptions(
          options,
          onSuccess,
          onError
        );
      } catch (error) {
        // TODO: Add sharing in browser here.
        console.log("Sharing failed: " + error);
      }
    },
    openPage(link) {
      // Prevent access to ticketdetails without login
      if (!this.$store.state.session.user && !this.$store.state.isMiKY) {
        this.$router.safePush({ name: "login" });
      } else {
        if (
          this.$store.state.isMiKY &&
          (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
        ) {
          window.location.assign(link);
        } else {
          window.open(link, "_system");
        }
      }
    },
    ...mapMutations({
      setMarker: "map/SET_MARKER",
      setCenter: "map/SET_CENTER",
    }),
    toggle(id) {
      if (
        !find(this.$store.state.profile.data.favorites, (fav) => {
          return fav === id;
        })
      ) {
        if (!this.$store.state.profile.data.favorites) {
          this.$store.state.profile.data.favorites = [];
        }
        this.$store.state.profile.data.favorites.push(id);
      } else {
        this.$store.state.profile.data.favorites.splice(
          indexOf(this.$store.state.profile.data.favorites, id),
          1
        );
      }

      this.$store.dispatch(
        "profile/updateProfile",
        {
          profile: this.$store.state.profile.data,
        },
        { root: true }
      );
    },
    find,
  },
};
</script>
<style>
.v-rating .v-icon {
  padding: 0;
}
.Icons-Outlines-16px-Read-more {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-left: -4px;
  margin-bottom: -4px;
}
.Icons-Outlines-16px-Read-less {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-left: -4px;
  margin-bottom: -3px;
}
</style>
