<template>
  <v-container
    v-if="
      (reserveouttickets && reserveouttickets.length > 0) ||
      (pccmtickets && pccmtickets.length > 0)
    "
    class="pt-1 pb-0 mb-n1"
  >
    <div class="py-0" style="display: flex">
      <img class="color-filter" src="@/assets/img/tickets.svg" style="" />
      <span class="pt-1 pl-1 Heading-Dark-H5-16-Left-Align">
        {{ $t("tickets.tickets") }}
      </span>
    </div>
    <div v-if="pccmtickets">
      <v-card
        v-for="(ticket, index) in pccmtickets"
        :key="`pccm-${index}`"
        class="ticket"
      >
        <div>
          <v-img
            eager
            height="133px"
            style="border-radius: 12px !important"
            :src="
              ticket.imageGallery
                ? ticket.imageGallery[0].url
                : recommendationImageUrl
            "
          >
          </v-img>
          <div class="mx-2 mt-2 Heading-Dark-H4-18-Left-Align">
            {{ ticket.header }}
          </div>
          <div class="mx-2 Text-Dark-Text-2---16-Left-Align">
            {{ ticket.linkedRecommendation.text }}
          </div>
          <div class="ticket-tear-line"></div>
          <v-row>
            <v-col class="py-0" cols="8">
              <span class="ticket-price Heading-Dark-H5-16-Left-Align">
                {{
                  $t("tickets.price", {
                    price: ticket.price,
                    currency: ticket.currency.code,
                  })
                }}
              </span>
            </v-col>
            <v-col class="py-0" cols="4">
              <div class="button-wrapper">
                <v-btn
                  class="button gradient Heading-White-H6-14-Left-Align"
                  color="primary"
                  @click="openTicket(ticket.id, 'pccm')"
                >
                  {{ $t("tickets.getTicket") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
    <div v-if="reserveouttickets">
      <v-card
        v-for="(ticket, index) in reserveouttickets"
        :key="`RO-${index}`"
        class="ticket"
      >
        <div>
          <v-img
            eager
            height="133px"
            style="border-radius: 12px !important"
            :src="highResReserveoutImage(ticket)"
            :lazy-src="ticket.imageUrl"
          >
          </v-img>

          <div class="mx-2 mt-2 Heading-Dark-H4-18-Left-Align">
            {{ ticket.name }}
          </div>
          <div class="mx-2 Text-Dark-Text-2---16-Left-Align">
            {{ ticket.placeName }}
          </div>
          <div class="ticket-tear-line"></div>
          <div class="button-wrapper">
            <v-btn
              class="button gradient Heading-White-H6-14-Left-Align"
              color="primary"
              @click="openTicket(ticket.id, 'reserve-out')"
            >
              {{ $t("tickets.getTicket") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import find from "lodash/find";
import filter from "lodash/filter";
import { mapActions } from "vuex";

export default {
  name: "tickets",
  data() {
    return {};
  },
  computed: {
    reserveouttickets() {
      let tickets = filter(this.$store.state.reserveout.promotions, (o) => {
        return o.placeId === this.$store.state.recommendations.details.wowId;
      });
      return tickets;
    },
    pccmtickets() {
      return this.$store.state.recommendations.tickets;
    },
    recommendationImageUrl() {
      return this.$store.state.recommendations.details.imageGallery[0];
    },
  },
  methods: {
    ...mapActions("favorites", ["toggleFavorite"]),
    find,
    openTicket(ticketId, ticketstype) {
      this.$router.safePush({
        path: `/ticketdetail/${this.$route.params.id}/${ticketstype}/${ticketId}`,
      });
    },
    highResReserveoutImage(ticket) {
      let imageUrl = ticket.imageUrl;
      let imageSource = imageUrl.slice(0, 49);
      let imageName = imageUrl.split("/")[imageUrl.split("/").length - 1];
      let highResUrl = imageUrl;
      if (imageSource === "https://ro-images-reserveout.netdna-ssl.com/crop/") {
        highResUrl = imageSource + "1404x936/" + imageName;
      }
      return highResUrl;
    },
  },
};
</script>
<style scoped>
.ticket {
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 13px;
  margin-bottom: 17px;
  padding-bottom: 14px;
  box-shadow: none !important;
  border-radius: 12px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  filter: drop-shadow(1px 3px 5px var(--v-shadowonbackground-base));
  position: relative;
  color: black;
  font-size: 16px;
  background-size: 51% 100%;
  background-repeat: no-repeat;
  background-image: radial-gradient(
      circle at 0 calc(100% - 64px),
      rgba(255, 255, 255, 0) 10px,
      var(--v-background-base) 11px
    ),
    radial-gradient(
      circle at 100% calc(100% - 64px),
      rgba(255, 255, 255, 0) 10px,
      var(--v-background-base) 11px
    ) !important;
  background-position: top left, top right;
}
.ticket-tear-line {
  margin-top: 17px;
  border-top: 6px dotted #f1f1f1;
  margin-left: 15px;
  margin-right: 15px;
}
.button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 10px;
  margin-top: 23px;
}
.button {
  width: 95px !important;
  height: 24px !important;
  padding: 0px !important;
  object-fit: contain;
  border-radius: 16px;
  box-shadow: none;
}
.ticket-price {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin-top: 23px;
}
</style>
