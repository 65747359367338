<template>
  <div>
    <div class="contact" v-if="item.phone">
      <v-btn tag="a" :href="'tel:' + item.phone" icon @click.stop>
        <img src="@/assets/img/phone.svg" />
      </v-btn>
      <span class="caption Text-Dark-Text-4---12-Center">{{
        $t("detail.phone")
      }}</span>
    </div>
    <div class="contact" v-if="item.whatsapp">
      <v-btn icon>
        <img src="@/assets/img/whatsapp.svg" />
      </v-btn>
      <span class="caption Text-Dark-Text-4---12-Center">whatsapp</span>
    </div>
    <div class="contact" v-if="item.email">
      <v-btn
        tag="a"
        :href="'mailto:' + item.email"
        @click.stop
        icon
        v-if="item.email"
      >
        <img src="@/assets/img/email.svg" />
      </v-btn>
      <span class="caption Text-Dark-Text-4---12-Center">{{
        $t("detail.email")
      }}</span>
    </div>
    <div class="contact Text-Dark-Text-4---12-Center" v-if="item.webAddresses">
      <v-btn tag="a" @click="openPage(item.webAddresses)" @click.stop icon>
        <img src="@/assets/img/website.svg" />
      </v-btn>
      <span class="caption Text-Dark-Text-4---12-Center">{{
        $t("detail.web")
      }}</span>
    </div>
    <div
      class="contact Text-Dark-Text-4---12-Center"
      v-if="menus && menus.length && hasValidSections === true"
    >
      <v-btn tag="a" color="primary" @click="openMenu(item.wowId)" icon>
        <img src="@/assets/img/menu.svg" />
      </v-btn>
      <span class="caption Text-Dark-Text-4---12-Center"
        >{{ $t("detail.menu") }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "BottomButtons",
  data() {
    return {};
  },
  props: ["item"],
  computed: {
    menus() {
      return this.$store.state.reserveout.menu;
    },
    hasValidSections() {
      for (var i = 0; i < this.$store.state.reserveout.menu.length; i++) {
        if (this.$store.state.reserveout.menu[i].sections.length > 0) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapMutations({
      setMarker: "map/SET_MARKER",
      setCenter: "map/SET_CENTER",
    }),
    //needed for native build
    openPage(link) {
      if (
        this.$store.state.isMiKY &&
        (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
      ) {
        window.location.assign(link);
      } else {
        window.open(link, "_system");
      }
    },
    openMenu(restaurantId) {
      this.$router.safePush({
        path: `/menu/${restaurantId}`,
      });
    },
  },
};
</script>
<style scoped>
.contact {
  display: inline-block;
  text-align: center;
  padding-right: 8px;
  padding-left: 8px;
}
.contact .caption {
  display: flex;
  justify-content: center;
}
</style>
