var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-skeleton-loader',{attrs:{"type":"card, article","loading":_vm.$store.state.recommendations.detailStatus === 'fetching' &&
    _vm.$route.params.id !== _vm.item.id}},[(_vm.item)?_c('v-container',{staticClass:"pt-0 px-0 margin-bottom-custom",staticStyle:{"overflow-x":"hidden"}},[(_vm.item.source === 'musement')?_c('musement',{attrs:{"item":_vm.item,"readMoreActivated":_vm.readMoreActivated,"ratings":_vm.ratings,"openingTimes":_vm.openingTimes,"validSubcategories":_vm.validSubcategories,"displayedAddress":_vm.displayedAddress,"distance":_vm.distance,"filteredRecommendations":_vm.filteredRecommendations},on:{"read-more":_vm.readMore,"toggle":function (id) {
          _vm.toggleFavorite(id);
        },"show-ratings":function($event){_vm.showRating = true}}}):_vm._e(),(_vm.item.source === 'ticketmaster')?_c('ticketmaster',{attrs:{"item":_vm.item,"readMoreActivated":_vm.readMoreActivated,"ratings":_vm.ratings,"openingTimes":_vm.openingTimes,"validSubcategories":_vm.validSubcategories,"displayedAddress":_vm.displayedAddress,"distance":_vm.distance,"filteredRecommendations":_vm.filteredRecommendations},on:{"toggle":function (id) {
          _vm.toggleFavorite(id);
        },"show-ratings":function($event){_vm.showRating = true},"read-more":_vm.readMore}}):_vm._e(),(_vm.item.source === 'getyourguide')?_c('get-your-guide',{attrs:{"item":_vm.item,"readMoreActivated":_vm.readMoreActivated,"ratings":_vm.ratings,"openingTimes":_vm.openingTimes,"validSubcategories":_vm.validSubcategories,"displayedAddress":_vm.displayedAddress,"distance":_vm.distance,"filteredRecommendations":_vm.filteredRecommendations},on:{"toggle":function (id) {
          _vm.toggleFavorite(id);
        },"show-ratings":function($event){_vm.showRating = true},"read-more":_vm.readMore}}):_vm._e(),(
        _vm.item.source === 'V1' || !_vm.item.source || _vm.item.source === 'reserve-out'
      )?_c('v1',{attrs:{"item":_vm.item,"readMoreActivated":_vm.readMoreActivated,"ratings":_vm.ratings,"openingTimes":_vm.openingTimes,"validSubcategories":_vm.validSubcategories,"displayedAddress":_vm.displayedAddress,"distance":_vm.distance,"filteredRecommendations":_vm.filteredRecommendations},on:{"toggle":function (id) {
          _vm.toggleFavorite(id);
        },"show-ratings":function($event){_vm.showRating = true},"read-more":_vm.readMore}}):_vm._e(),_c('v-bottom-sheet',{attrs:{"scrollable":""},model:{value:(_vm.showRating),callback:function ($$v) {_vm.showRating=$$v},expression:"showRating"}},[_c('v-sheet',{staticClass:"pt-4",staticStyle:{"border-radius":"20px 20px 0 0"},attrs:{"height":"85vh"}},[_c('div',{staticClass:"reviews"},_vm._l((_vm.ratings),function(item,index){return _c('v-card',{key:("rating-" + index),attrs:{"flat":""}},[_c('v-row',{staticClass:"pl-4",attrs:{"dense":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-img',{attrs:{"height":"44","width":"44","src":item.img && item.img.length > 0
                      ? item.img
                      : require('@/assets/avatar.png')}})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"5"}},[_c('v-card-text',{staticClass:"py-0"},[_vm._v(_vm._s(item.alias))]),_c('v-card-text',{staticClass:"py-0 caption"},[_vm._v(_vm._s(_vm.$t("review.postedAt", { date: item.postDate })))])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-rating',{staticClass:"d-inline-block mr-2",attrs:{"readonly":"","background-color":"#feeecb","empty-icon":"mdi-star","full-icon":"mdi-star","length":"5","size":"20","value":parseInt(item.rating),"color":"#fdbd2f"}})],1)],1),_c('v-card-text',{staticClass:"pt-0 text-left"},[_vm._v(_vm._s(item.comment))])],1)}),1)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }