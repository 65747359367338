<template>
  <v-list flat class="transparent pt-2">
    <h4 class="text-center">{{ $t("detail.otherDates") }}</h4>
    <template v-for="(event, index) in events">
      <v-list-item v-if="index > 0" :key="'event- ' + index">
        <v-list-item-avatar>
          <v-icon>mdi-calendar</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{
            event.date + ", " + event.time
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          ><v-btn color="primary" icon @click="openPage(event.webpage)">
            <v-icon>mdi-cart-outline</v-icon></v-btn
          ></v-list-item-action
        >
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  props: ["events"],
  methods: {
    openPage(link) {
      if (
        this.$store.state.isMiKY &&
        (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
      ) {
        window.location.assign(link);
      } else {
        window.open(link, "_system");
      }
    },
  },
};
</script>
